import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'ng-block-ui';
import { CookieService } from 'ng2-cookies';
import { CookieService as KaleidaCookieService } from 'app/shared/services/cookie.service';
import { LoggingService } from 'app/shared/services/log.service';
import { ApplicationService } from './services/application.service';
import { IconActionButtonComponent } from 'app/shared/components/icon-action-button/icon-action-button.component';
import { IconActionButtonListComponent } from 'app/shared/components/icon-action-button-list/icon-action-button-list.component';
import { ListFilterComponent } from 'app/shared/components/list-filter/list-filter.component';
import { ListFilterInlineComponent } from 'app/shared/components/list-filter/list-filter-inline.component';
import { PageTitleComponent } from 'app/shared/components/page-title/page-title.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { LogDataService } from 'app/shared/services/log.data.service';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { SnackBarService } from './services/snack-bar.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ListPageJumperComponent } from './components/list-page-jumper/list-page-jumper.component';
import { SimpleFileUploadComponent } from './components/simple-file-upload/simple-file-upload.component';
import { InternalFileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActiveInactivePipe } from './pipes/active-inactive.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { SharedRoutesModule } from './shared-routes/shared-routes.module';
import { RouterModule } from '@angular/router';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { ToBeImplementedComponent } from './components/to-be-implemented/to-be-implemented.component';
import { UrlLinkPipe } from './pipes/url-link.pipe';
import { EmailLinkPipe } from './pipes/email-link.pipe';
import { PostcodeLookupComponent } from 'app/shared/components/postcode-lookup/postcode-lookup.component';
import { PostcodeLookupService } from 'app/shared/components/postcode-lookup/services/postcode-lookup.service';

import { CompanyCreditLookupService } from './components/company-credit-lookup/services/company-credit-lookup.service';
import { CompanyCreditLookupComponent } from './components/company-credit-lookup/company-credit-lookup.component';
import { CompanyLookupComponent } from './components/company-lookup/company-lookup.component';
import { CompanyLookupService } from './components/company-lookup/services/company-lookup.service';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { GoogleMapComponent } from 'app/shared/components/google-map/google-map.component';
import { GoogleMapsService } from 'app/shared/components/google-map/services/google-map.service';
import { environment } from 'environments/environment';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { KaleidaPopoverDirective } from './components/popover/pop-over.directive';
import { KaleidaPopoverComponent } from './components/popover/pop-over.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { ConcurrencyErrorComponent } from './components/concurrency-error/concurrency-error.component';
import { SagePayComponent } from './components/sage-pay/sage-pay.component';
import { SagePayService } from 'app/shared/components/sage-pay/services/sage-pay.service';
import { IsSystemPipe } from './pipes/IsSystem.pipe';
import { YesBlankPipe } from './pipes/yes-blank.pipe';
import { DateTimeComponent } from './components/df-date-time/df-date-time.component';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { CoreSharedModule } from './core-shared.module';
import { WarningBoxComponent } from './components/warning-box/warning-box.component';
import { KeepAliveService } from './services/keep-alive.service';

@NgModule({
  imports: [
    CommonModule,
    BlockUIModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    RouterModule,
    FileUploadModule,
    FlexLayoutModule,
    AppMaterialModule,
    CoreSharedModule,
    AgmCoreModule.forRoot({ apiKey: environment.googleMapsAPIKey }),
    SharedRoutesModule,
  ],
  declarations: [
    // ConfirmationModalComponent,
    IconActionButtonComponent,
    IconActionButtonListComponent,
    ListFilterComponent,
    ListFilterInlineComponent,
    PageTitleComponent,
    //SnackBarComponent,
    ListPageJumperComponent,
    SimpleFileUploadComponent,
    InternalFileUploaderComponent,
    ActiveInactivePipe,
    YesNoPipe,
    YesBlankPipe,
    IsSystemPipe,
    UrlLinkPipe,
    EmailLinkPipe,
    //SafeHtmlPipe,
    NoResultsComponent,
    AlertBoxComponent,
    ToBeImplementedComponent,
    PostcodeLookupComponent,
    CompanyCreditLookupComponent,
    CompanyLookupComponent,
    CompanyCreditLookupComponent,
    GoogleMapComponent,
    BreadcrumbComponent,
    KaleidaPopoverDirective,
    KaleidaPopoverComponent,
    ConcurrencyErrorComponent,
    SagePayComponent,
    DateTimeComponent,
    WarningBoxComponent
  ],
  entryComponents: [
    // ConfirmationModalComponent, 
//    SnackBarComponent, 
    KaleidaPopoverComponent,
    SimpleFileUploadComponent
  ],
  exports: [
    BlockUIModule,
    FlexLayoutModule,
    CoreSharedModule,
    // ConfirmationModalComponent,
    IconActionButtonComponent,
    IconActionButtonListComponent,
    ListFilterComponent,
    ListFilterInlineComponent,
    PageTitleComponent,
    //SnackBarComponent,
    ListPageJumperComponent,
    SimpleFileUploadComponent,
    InternalFileUploaderComponent,
    ActiveInactivePipe,
    YesNoPipe,
    YesBlankPipe,
    IsSystemPipe,
    UrlLinkPipe,
    EmailLinkPipe,
    //SafeHtmlPipe,
    NoResultsComponent,
    AlertBoxComponent,
    ToBeImplementedComponent,
    PostcodeLookupComponent,
    CompanyCreditLookupComponent,
    CompanyLookupComponent,
    CompanyCreditLookupComponent,
    GoogleMapComponent,
    BreadcrumbComponent,
    KaleidaPopoverDirective,
    ConcurrencyErrorComponent,
    SagePayComponent,    
    DateTimeComponent,
    WarningBoxComponent,
  ],
  providers: [
    SnackBarService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { verticalPosition: 'top', horizontalPosition: 'right' }
    },
    KaleidaCookieService,
    CookieService,
    // Providers needed for asideService.setComponent() calls! These should only really be needed in the offline module.
    ApplicationService,
    { provide: 'windowObject', useValue: window },
    { provide: 'navigatorObject', useValue: navigator },
    ReactiveFormHelperService,
    LoggingService,
    LogDataService,
    PostcodeLookupService,
    CompanyCreditLookupService,
    CompanyLookupService,
    CompanyCreditLookupService,
    GoogleMapsAPIWrapper,
    GoogleMapsService,
    SagePayService,
    KeepAliveService,
  ]
})
export class SharedModule {}
