import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { InternalFileUploaderComponent } from 'app/shared/components/file-uploader/file-uploader.component';
import { CostCategory } from 'app/shared/enumeration/cost-category';
import { CostPayeeType } from 'app/shared/enumeration/cost-payee-type';
import { InsuranceType } from 'app/shared/enumeration/insurance-type';
import { formatAsString } from 'app/shared/helpers/date-helpers';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { dateLessOrEqualThan, fileRequiredIf, requiredIf } from 'app/shared/validation/custom-validators';
import { RegexValidationStrings } from 'app/shared/validation/reg-ex-validation-constants';
import { CostModel } from '../models/cost.model';
import { CostListService } from '../services/cost-list.service';

@Component({
  selector: 'cost-form',
  templateUrl: './cost-form.component.html',
})
export class CostFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;
  concurrencyErrorMessage: string;
  categoryId: number;
  emailIdForBatch: number; // if we're creating a batch cost, this is the email ID containing the attachments to batch

  costCategoryLookupData: any[];
  costTypeLookupData: any[];
  costPayeeTypeLookupData: any[];
  supplierTypeLookupData: any[];

  viewModel: CostModel;
  dataForm: FormGroup;

  fileUploaderTouched = false;
  uploadEndpoint = '';
  isThirdParty = false;
  isSupplier = false;

  suppressListRefreshOnEdit = false; // if true allows the caller to deal with list reloads

  today: Date = new Date();
  @ViewChild(InternalFileUploaderComponent, { static: false }) fileUploaderComponent: InternalFileUploaderComponent;
  warningMessage: string;

  constructor(private fb: FormBuilder, private service: CostListService, private asideService: AsideService, private formHelperService: ReactiveFormHelperService, private dialog: MatDialog) {
    this.dataForm = fb.group(
      {
        costCategory: ['', [Validators.required]],
        costTypeId: [''],
        submittedAmount: ['', [Validators.required, Validators.min(0.01), Validators.pattern(RegexValidationStrings.DECIMAL_TWO)]],
        submissionDate: ['', [Validators.required]],
        invoiceDate: ['', [Validators.required]],
        invoiceReference: ['', [Validators.required]],
        // see ngOnInit for dateLessOrEqualThan validation on submission date
        payeeType: [''],
        payee: ['', [Validators.maxLength(100), requiredIf(this.isPayeeRequired.bind(this))]],
        supplier: ['', [requiredIf(this.isPayeeTypeSupplier.bind(this))]],
      },
      {
        validator: Validators.compose([fileRequiredIf(this.isFileRequired.bind(this), this.numberOfFilesToUpload.bind(this))]),
      }
    );
  }

  // shortcuts to controls for readability
  get costCategory(): AbstractControl {
    return this.dataForm.controls['costCategory'];
  }
  get costTypeId(): AbstractControl {
    return this.dataForm.controls['costTypeId'];
  }
  get submittedAmount(): AbstractControl {
    return this.dataForm.controls['submittedAmount'];
  }
  get submissionDate(): AbstractControl {
    return this.dataForm.controls['submissionDate'];
  }
  get invoiceReference(): AbstractControl {
    return this.dataForm.controls['invoiceReference'];
  }
  get invoiceDate(): AbstractControl {
    return this.dataForm.controls['invoiceDate'];
  }
  get payeeType(): AbstractControl {
    return this.dataForm.controls['payeeType'];
  }
  get payee(): AbstractControl {
    return this.dataForm.controls['payee'];
  }
  get supplier(): AbstractControl {
    return this.dataForm.controls['supplier'];
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.saveText = this.editMode ? 'Update' : 'Create';
    this.emailIdForBatch = this.data.emailIdForBatch;

    this.uploadEndpoint = this.editMode ? this.service.getUpdateWithUploadEndpoint(this.data.itemId) : this.service.getUploadEndpoint();
    this.loadFormDetails();

    if (this.data.submissionDateOverride != undefined) {
      // Allow an alternate submission date to be injected in from the parent component
      this.submissionDate.setValue(this.data.submissionDateOverride);
    }

    // left in constructor in case reqs change again and this is only needed for creates as it's changed a few times
    this.submissionDate.setValidators([Validators.required, dateLessOrEqualThan(this.today.toString())]);
    this.submissionDate.updateValueAndValidity();

    this.suppressListRefreshOnEdit = this.data.suppressListRefreshOnEdit === true;
  }

  private loadFormDetails() {
    const id: number = this.editMode ? this.data.itemId : null;

    this.service.getEditableItemDetail(id).then((response: CostModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.concurrencyErrorMessage = '';
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    this.submittedAmount.setValidators([Validators.required, Validators.min(this.viewModel.minSubmittedAmount), Validators.pattern(RegexValidationStrings.DECIMAL_TWO)]);
    this.submittedAmount.updateValueAndValidity();

    this.costCategoryLookupData = this.formHelperService.createLookupData(this.viewModel.costCategoryLookupData);
    this.costCategory.valueChanges.subscribe((value) => this.onCategoryChange(value));
    this.payeeType.valueChanges.subscribe((value) => this.onPayeeTypeChange(value));
    this.costTypeId.valueChanges.subscribe((value) => this.onCostTypeChange(value));
    this.supplier.valueChanges.subscribe((value) => this.onSupplierChange(value));
    this.dataForm.controls['payee'].setValue(this.viewModel.payee);

    this.dataForm.controls['costCategory'].setValue(this.viewModel.costCategory);
    this.dataForm.controls['costTypeId'].setValue(this.viewModel.costTypeId);
    this.dataForm.controls['submittedAmount'].setValue(this.viewModel.submittedAmount);
    this.dataForm.controls['submissionDate'].setValue(this.viewModel.submissionDate);

    this.dataForm.controls['invoiceReference'].setValue(this.viewModel.invoiceReference);
    this.dataForm.controls['invoiceDate'].setValue(this.viewModel.invoiceDate);

    this.dataForm.controls['payeeType'].setValue(this.viewModel.payeeType);
    this.dataForm.controls['supplier'].setValue(this.viewModel.payeeCompanyId);
    this.costPayeeTypeLookupData = this.formHelperService.createLookupData(this.viewModel.costPayeeTypeLookupData);

    this.costPayeeTypeDefault();
    this.categoryAndTypeDefault();

    this.submittedAmount.valueChanges.subscribe(() => this.setWarningMessage());
    this.costCategory.valueChanges.subscribe(() => this.setWarningMessage());

    this.dataForm.markAsPristine();
  }

  onSupplierChange(companyId: number) {
    if (companyId === undefined || companyId === null) {
      return;
    }

    const supplierName = this.supplierTypeLookupData.find((f) => f.id == companyId).text;
    this.payee.setValue(supplierName);
  }

  onPayeeTypeChange(payeeType: number) {
    if (payeeType == CostPayeeType.ThirdParty && this.editMode) {
      this.isThirdParty = true;
      this.payee.patchValue(this.viewModel.payee);
    } else if (payeeType == CostPayeeType.ThirdParty) {
      this.isThirdParty = true;
      this.payee.patchValue(undefined);
    } else {
      this.isThirdParty = false;
    }

    if (payeeType == CostPayeeType.Supplier) {
      this.isSupplier = true;
    } else {
      this.isSupplier = false;
      this.supplier.patchValue(undefined);
    }

    if (payeeType == CostPayeeType.Client || payeeType == CostPayeeType.PolicyHolder) {
      const dataLookup = this.viewModel.costPayeeTypeLookupData.items.find((f) => f.id == payeeType).data;

      this.payee.setValue(dataLookup);
    }
  }

  onCategoryChange(categoryId: number) {
    if (categoryId === undefined) {
      return;
    }

    this.categoryId = categoryId;

    this.costTypeId.patchValue(undefined);
    this.supplierTypeLookupData = undefined;

    this.costTypeLookupData = [];

    const categoryLookup = this.viewModel.costCategoryLookupData.items.find((f) => f.id === categoryId);
    if (categoryLookup != null) {
      this.costTypeLookupData = this.formHelperService.createLookupData(categoryLookup.subLists[0]);
    }
  }

  onCostTypeChange(costTypeId: number) {
    if (costTypeId === undefined || this.categoryId === undefined || costTypeId == null) {
      return;
    } 
    else {
      this.supplier.patchValue(undefined);
      const costCategoryLookup = this.viewModel.costCategoryLookupData.items.find((f) => f.id === this.categoryId);
      const costTypeWithData = this.formHelperService.createLookupDataWithDataObject(costCategoryLookup.subLists[0]).find((f) => f.id == costTypeId);
      const supplierTypeId = costTypeWithData.data;
      const supplierTypeLookup = this.viewModel.supplierTypeLookupData.items.find((f) => f.id === supplierTypeId);
      const suppliers = supplierTypeLookup === undefined ? null : supplierTypeLookup.subLists[0];

      if (suppliers != null) {
        this.supplierTypeLookupData = this.formHelperService.createLookupData(suppliers);
      }
    }
  }

  fileUploadQueueChanged() {
    this.fileUploaderTouched = true;
    this.dataForm.updateValueAndValidity();
  }

  costPayeeTypeDefault() {
    if (!this.editMode) {
      if (this.viewModel == undefined || this.viewModel.insuranceTypeOfPolicy == undefined || this.viewModel.insuranceTypeOfPolicy == null || this.viewModel.insuranceTypeOfPolicy == InsuranceType.ATE) {
        this.payeeType.setValue(CostPayeeType.Client);
      } else {
        this.payeeType.setValue(CostPayeeType.Supplier);
      }
    }
  }

  categoryAndTypeDefault() {
    if (this.isCreatingBatchedCost()) {
      this.costCategory.setValue(this.viewModel.categoryIdForBatchedCosts);
      this.costTypeId.setValue(this.viewModel.costTypeIdForBatchedCosts);
    }
  }

  isPayeeRequired() {
    return this.isThirdParty == true;
  }

  isPayeeTypeSupplier() {
    return this.dataForm && this.payeeType.value === CostPayeeType.Supplier;
  }

  // limitOfLiabilityExceeded(): boolean{
  //   debugger;
  //   if(this.viewModel==undefined) return false;
  //   let costIsNotRefund = this.costCategory.value && this.costCategory.value != CostCategory.Refund;
  //   let limitReached = this.submittedAmount.value > this.viewModel.limitOfLiability;
  //   return this.viewModel.applyLimitOfLiability && limitReached && costIsNotRefund;
  // }

  setWarningMessage() {
    let costIsNotRefund = this.costCategory.value && this.costCategory.value != CostCategory.Refund;
    let limitReached = +this.submittedAmount.value > +this.viewModel.limitOfLiability;
    if (this.viewModel.applyLimitOfLiability && limitReached && costIsNotRefund) {
      this.warningMessage = 'Limit of Liability has been reached.';
    } else {
      this.warningMessage = '';
    }
  }

  preventCostTypeChanges(): boolean {
    if (this.viewModel == undefined) return true;
    if (this.isCreatingBatchedCost()) return true;
    return this.viewModel.preventCostTypeChanges;
  }

  preventEvidenceChanges(): boolean {
    if (this.viewModel == undefined) return true;
    if (this.isCreatingBatchedCost()) return true;
    return this.viewModel.preventEvidenceChanges;
  }

  isCreatingBatchedCost(): boolean {
    if (this.viewModel == undefined) return false;
    return this.emailIdForBatch != undefined;
  }

  // TODO: Many of the other functions in this component should probably be get properties like this one, really.
  // But it'd mean going through the HTML updating everything, so left for now.
  get formTitle(): string {
    return this.isCreatingBatchedCost() ? 'Create Batched Costs' : this.saveText + ' Cost';
  }

  isFileRequired() {
    return this.fileUploaderTouched === true && this.editMode === false && this.viewModel.fileUploadRequired && !this.isCreatingBatchedCost();
  }

  numberOfFilesToUpload() {
    return this.fileUploaderComponent.filesToUpload;
  }

  private uploadDocument(updateModel) {
    this.fileUploaderComponent.headers.push({ name: 'X-JsonData', value: JSON.stringify(updateModel) });
    this.fileUploaderComponent.onApply();
  }

  fileUploadComplete(): void {
    this.fileUploaderComponent.clearQueue();
    this.service.reload();
    this.asideService.close('update');
  }

  private createUpdateModel() {
    return {
      divisionId: this.data.divisionId,
      claimId: this.data.claimId,
      costTypeId: this.costTypeId.value,
      submittedAmount: this.submittedAmount.value,
      submissionDate: formatAsString(this.submissionDate.value),
      invoiceDate: formatAsString(this.invoiceDate.value),
      invoiceReference: this.invoiceReference.value,
      payeeType: this.payeeType.value,
      payee: this.payee.value,
      payeeCompanyId: this.payeeType.value == CostPayeeType.Supplier ? this.supplier.value : undefined,

      timestamp: this.viewModel.timestamp,
    };
  }

  save() {
    this.fileUploaderTouched = true;
    this.dataForm.updateValueAndValidity();

    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();

      if (this.emailIdForBatch != null) {
        // Creation of batched costs. Similar requirements to CreateCostActionHandler but we need to pass the email ID in
        // We'll use a different endpoint / route for this
        var endpoint = this.service.getCreateEndpointForBatchedCostCreationFromInboundEmail(this.emailIdForBatch);

        this.service.addNew(updateModel, endpoint).then(() => {
          this.asideService.close('update');
        });
      } else if (this.fileUploaderComponent.uploader.queue.length > 0) {
        // Calls UploadCostActionHandler which appears to deal with both creates and updates when a file is present
        this.uploadDocument(updateModel);
      } else if (this.editMode) {
        // Calls UpdateCostActionHandler which deals with updates with no new file
        if (this.suppressListRefreshOnEdit) this.service.allowReload = false;
        this.service.updateItem(this.data.itemId, updateModel).then(() => {
          if (this.suppressListRefreshOnEdit) this.service.allowReload = true; // caller will deal with the reload
          this.asideService.close('update');
        });
      } else {
        // Calls CreateCostActionHandler which deals with creates with no file
        // Only used for standard cost creates, not for batched cost creates from an email
        this.service.addNew(updateModel).then(() => {
          this.asideService.close('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?',
        },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }
}
